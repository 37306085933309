import { SerializedFarmConfig } from '@pancakeswap/farms'
import { coreTokens, qomTokens } from '@pancakeswap/tokens'

const priceHelperLps: SerializedFarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'WQOM-qUSDT LP',
    lpAddress: '0xA21d14Fe48f48556f760Fa173aE9Bc3f6a996C5B',
    token: qomTokens.wqom,
    quoteToken: qomTokens.usdt,
  },
  {
    pid: 1,
    lpSymbol: 'WQOM-QSWAP LP',
    lpAddress: '0x2c9e7BC2f7Cb66BDd7989F1f485D7640E5967173',
    token: qomTokens.qswap,
    quoteToken: qomTokens.wqom,
  },
].map((p) => ({
  ...p,
  token: p.token?.serialize ? p.token.serialize : p.token,
  quoteToken: p.quoteToken?.serialize ? p.quoteToken.serialize : p.quoteToken,
}))

export default priceHelperLps
